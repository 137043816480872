import { usePage } from "@inertiajs/vue3";
import _ from "lodash";

export { parseColor } from "./colors";

// import { kebabCase } from "lodash";
// _.kebabCase case was splitting up char/number in sku

const slugify = (str) =>
    str
        .toLowerCase() // lower to prevent camelcase convertion
        .trim() // remove leading or trailing whitespace
        .replace(/&.*?;/g, "") // remove html entities
        .replace(/[^\w\s-]/g, "") // remove other non-characters
        .replace(/[\s_-]+/g, "-") // replace whitespace with dash
        .replace(/^-+|-+$/g, ""); // revmove double dash

const nestedErrors = (errors, path, index) => {
    path = path.replace("", index);
    return _.get(errors, path);
};

const formatCurrency = (amount) => {
    const dollars = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        useGrouping: true,
    });

    return dollars.format(amount);
};

const removeCssSelector = (path, cssSelector) => {
    // depends on vite
    Array.from(document.styleSheets)
        .filter((sheet) => {
            sheet._href = sheet.href ?? sheet.ownerNode?.dataset["viteDevId"];
            return (
                typeof sheet.modified === "undefined" &&
                sheet._href?.includes(path)
            );
        })
        .map((sheet) => {
            sheet.modified = true;
            Array.from(sheet.cssRules)
                .filter((rule, index) => {
                    rule.index = index;
                    return rule.selectorText?.includes(cssSelector);
                })
                .forEach((rule) => {
                    console.warn(
                        `Removed CSS Style \`${rule.selectorText}\` from ${sheet._href}`
                    );
                    sheet.deleteRule(rule.index);
                });
            return sheet;
        });
};

function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (_.isObject(target) && _.isObject(source)) {
        for (const key in source) {
            if (_.isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

const adminRoute = (routeName, ...args) => {
    const isCompanyAdmin = usePage().props.auth.user.isCompanyAdmin;
    const routePrefix = isCompanyAdmin ? "company-admin." : "admin.";
    return route(routePrefix + routeName, ...args);
};

const actingAdminRoute = (routeName, ...args) => {
    const isCompanyAdmin = usePage().props.auth.user.isCompanyAdmin;
    const onCompanyAdminPage = !!route().params.company;
    const routePrefix =
        isCompanyAdmin || onCompanyAdminPage ? "company-admin." : "admin.";
    return route(routePrefix + routeName, ...args);
};

export {
    slugify,
    nestedErrors,
    formatCurrency,
    removeCssSelector,
    adminRoute,
    actingAdminRoute,
    mergeDeep,
};
